import React from 'react'
import {
  Button,
  Checkbox,
  Input,
  Textarea,
  IconLoader,
} from '@aristech/components'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'

export interface Data {
  name: string
  email: string
  message: string
}

interface Props {
  submitted: boolean
  submitting: boolean
  onSubmit: (data: Data) => void
  onReset: () => void
  messageRef: React.Ref<HTMLTextAreaElement>
}

const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.25s linear, transform 0.25s linear;
  text-align: center;
  -js-display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  .thank-you {
    padding-top: 3rem;
    h3 {
      font-weight: bold;
      margin-top: 0;
    }
  }
  a {
    margin-top: auto;
    cursor: pointer;
    color: #ccc;
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`
const Submitting = styled.div`
  -js-display: flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s linear;
  .loader {
    width: 50%;
    flex-shrink: 0;
  }
  p {
    margin: 0;
    padding-top: 1.5rem;
    color: ${props => props.theme.secondary};
  }
`
const Wrapper = styled.div`
  position: relative;
  form {
    transition: opacity 0.25s linear, transform 0.25s linear;
    position: relative;
    z-index: 1;
  }
  &.submitted {
    form {
      opacity: 0;
      z-index: 0;
      transform: translateY(-100px);
    }
    ${Success} {
      z-index: 1;
      opacity: 1;
      transform: none;
    }
  }
  &.submitting {
    form {
      opacity: 0;
      z-index: 0;
      transform: translateY(-100px);
    }
    ${Submitting} {
      z-index: 1;
      opacity: 1;
      transition-delay: 200ms; // wait for 200ms before showing the loader to prevent flashing when the submit is fast
    }
  }
`

const Form: React.FC<Props> = (props: Props) => {
  const { onSubmit, submitted, onReset, submitting, messageRef } = props

  const intl = useIntl()
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [accepted, setAccepted] = React.useState(false)
  const isValid = accepted && name.length && email.length && message.length

  const classNames: Array<string> = []
  if (submitting) {
    classNames.push('submitting')
  } else if (submitted) {
    classNames.push('submitted')
  }

  return (
    <Wrapper className={classNames.join(' ')}>
      <Submitting aria-hidden={submitting ? 'false' : 'true'}>
        <div className="loader">
          <IconLoader />
        </div>
        <p>{intl.formatMessage({ id: 'contact.sending' })}</p>
      </Submitting>
      <Success aria-hidden={submitted ? 'false' : 'true'}>
        <div className="thank-you">
          <h3>{intl.formatMessage({ id: 'contact.thank_you' })}</h3>
          <p>{intl.formatMessage({ id: 'contact.got_message' })}</p>
        </div>
        <a
          onClick={e => {
            e.preventDefault()
            setMessage('')
            setAccepted(false)
            onReset()
          }}
        >
          {intl.formatMessage({ id: 'contact.write_another_message' })}
        </a>
      </Success>
      <form aria-hidden={!submitted && !submitting ? 'false' : 'true'}>
        <Input
          label={intl.formatMessage({ id: 'contact.name' })}
          placeholder={intl.formatMessage({
            id: 'contact.name_placeholder',
          })}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          label={intl.formatMessage({ id: 'contact.email' })}
          placeholder={intl.formatMessage({
            id: 'contact.email_placeholder',
          })}
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Textarea
          textAreaRef={messageRef}
          value={message}
          onChange={e => setMessage((e.target as any).value)}
          label={intl.formatMessage({ id: 'contact.message' })}
          placeholder={intl.formatMessage({
            id: 'contact.message_placeholder',
          })}
        />
        <Checkbox
          label={intl.formatMessage({ id: 'contact.gdpr' })}
          checked={accepted}
          onChange={() => {
            setAccepted(!accepted)
          }}
        />
        <br />
        <Button
          type="submit"
          disabled={!isValid}
          color="primary"
          onClick={e => {
            e.preventDefault()
            onSubmit({ name, email, message })
          }}
        >
          {intl.formatMessage({ id: 'contact.send' })}
        </Button>
      </form>
    </Wrapper>
  )
}

export default Form
