import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import Box from './Box'
import useMedia from 'use-media'
// import Swiper from 'react-id-swiper'
import useSwiperA11y from '../../utils/useSwiperA11y'
import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// import 'swiper/swiper.scss'
// import 'swiper/components/a11y/a11y.scss'
// import 'swiper/components/controller/controller.scss'
// import 'swiper/components/pagination/pagination.scss'
// // import 'swiper/swiper-bundle.min.css'

import '../../style/swiper.css'
import '../../style/a11y.css'
import '../../style/pagination.css'

SwiperCore.use([Pagination, A11y])

const Wrapper = styled.div`
  -js-display: flex;
  display: flex;
  justify-content: space-around;
  padding: 0 1.5rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  ${Box} {
    margin: 1.5em 1em;
  }
  @media only screen and (max-width: 1200px) {
    max-width: none;
    margin-left: none;
    margin-right: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1.5rem;
    ${Box} {
      margin: 0;
      justify-self: center;
      &:nth-child(even) {
        justify-self: start;
      }
      &:nth-child(odd) {
        justify-self: end;
      }
    }
  }
`
const SwiperWrapper = styled.div`
  .swiper-container {
    padding-top: 2rem;
    padding-bottom: 5rem;
    padding: 2rem 1.5rem 5rem 1.5rem;
  }
`

interface Props {
  children: any
}

const Container: React.FC<Props> = (props: Props) => {
  const { children } = props
  const theme = React.useContext(ThemeContext)
  const isPortrait = useMedia({ maxWidth: theme.protraitBreakpoint })
  const a11y = useSwiperA11y()

  if (isPortrait) {
    /* const swiperProps = {
      spaceBetween: 30,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        // dynamicBullets: true,
      },
      a11y,
    } as any */
    return (
      <SwiperWrapper>
        {/* <Swiper {...swiperProps}>{children}</Swiper> */}
        <Swiper
          spaceBetween={30}
          slidesPerView="auto"
          centeredSlides
          loop
          pagination={{
            clickable: true,
          }}
          a11y={a11y}
        >
          {React.Children.map(children, child => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
    )
  }
  return <Wrapper>{children}</Wrapper>
}

export default Container
