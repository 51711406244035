import React from 'react'
import styled from 'styled-components'

const base_delay = 1.5

interface Props {
  mail: boolean
}

const Svg = styled.svg`
  position: absolute;
  z-index: 2;
  right: 1.5rem;
  top: 1.5rem;
  #NMFlag {
    transition: transform 0.25s ease-in-out;
    transform: rotate(55deg);
    transform-origin: 126px 105px;
    position: relative;
    z-index: 1;
  }
  #NMLetter {
    transform: translate(48px, -27px);
    transition: transform 0.25s linear;
  }
  &.mail {
    #NMFlag {
      transition-delay: ${base_delay + 0.25}s;
      transform: none;
    }
    #NMLetter {
      transition-delay: ${base_delay}s;
      transform: none;
    }
  }
`

const Mailbox: React.FC<Props> = (props: Props) => {
  const { mail } = props
  return (
    <Svg
      viewBox="0 0 217.1 302.1"
      width="141px"
      height="192px"
      className={mail ? 'mail' : ''}
    >
      <defs>
        <clipPath id="mailbox-inner">
          <path d="M106.1,106.5c0-20.8-16.8-37.6-37.6-37.6s-37.6,16.8-37.6,37.6v49.7l75.2,6.7V106.5z"></path>
        </clipPath>
      </defs>
      <g /* id="mailbox" */ clipPath="url(#form-header)">
        <g /* id="Lid" */>
          <path
            fill="#AAB8C2"
            d="M107.6,162l-18,47c0,0-12.9,31.2-57.9,23.7c-29.8-5-18.1-36.7-18.1-36.7l16.8-40.9L107.6,162z"
          />
          <path
            fill="#333"
            d="M106.1,162.9l-17.4,45.7c0,0-12.3,29.1-54.9,23.3c-31.8-4.3-19-36-19-36l16.1-39.7L106.1,162.9z"
          />
        </g>
        <g /* id="Box" */>
          <rect x="124" y="109.5" fill="#353535" width="24.5" height="192.7" />
          <linearGradient
            id="box-grad"
            gradientUnits="userSpaceOnUse"
            x1="83.5317"
            y1="45.059"
            x2="128.6589"
            y2="115.6427"
          >
            <stop offset="0" style={{ stopColor: '#AAB8C2' }} />
            <stop offset="1" style={{ stopColor: '#808080' }} />
          </linearGradient>
          <path
            fill="url(#box-grad)"
            d="M205.1,49.8v41l-97.7,73.6l-77.7-7v-51.3c0-13.2,6.6-24.9,16.7-31.9l0,0c1.7-1.2,3.6-2.3,5.5-3.2
			l92.9-53.7c6.1-4,13.5-6.4,21.3-6.4C187.7,11,205.1,28.4,205.1,49.8z"
          />
          <path
            fill="#333"
            d="M106.1,106.5c0-20.8-16.8-37.6-37.6-37.6s-37.6,16.8-37.6,37.6v49.7l75.2,6.7V106.5z"
          />
          <polygon
            fill="#1A1A1A"
            points="106.1,106.5 30.9,156.2 106.1,162.9 		"
          />
        </g>
        <g>
          <ellipse fill="#B54040" cx="124.4" cy="102.8" rx="3.1" ry="6.7" />
          <g id="NMFlag">
            <polygon
              fill="#CE4949"
              points="124.4,36.6 140.2,36.6 136.8,43 140.6,47.9 124.4,48.4"
            />

            <line
              fill="none"
              stroke="#F25050"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="124.4"
              y1="102.8"
              x2="124.4"
              y2="39.8"
            />
          </g>
        </g>
        <g /* id="Hinges" */>
          <line
            fill="none"
            stroke="#B3B3B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="44.1"
            y1="158.2"
            x2="55.6"
            y2="159.2"
          />

          <line
            fill="none"
            stroke="#B3B3B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="75.6"
            y1="161"
            x2="87.1"
            y2="162"
          />
        </g>

        <g clipPath="url(#mailbox-inner)">
          <g id="NMLetter">
            <g>
              <path
                fill="#F2F2F2"
                d="M133.3,116.2c0.1,0.6-0.3,1.2-0.9,1.4l-65.8,24.1l-1.9,0.7c-0.9,0.3-1.8-0.3-1.9-1.2l-3-41.9l-0.1-0.8
				c-0.1-0.6,0.3-1.2,0.9-1.4l67.3-24.9c0.9-0.3,1.8,0.3,1.9,1.2l0.1,1.3l3.3,40L133.3,116.2z"
              />
              <path
                fill="#E6E6E6"
                d="M133.3,116.2c0.1,0.6-0.3,1.2-0.9,1.4l-65.8,24.1l-1.9,0.7c-0.9,0.3-1.8-0.3-1.9-1.2l-3-41.9l-0.1-0.8
				c-0.1-0.6,0.3-1.2,0.9-1.4l67.3-24.9c0.9-0.3,1.8,0.3,1.9,1.2l0.1,1.3l3.3,40L133.3,116.2z"
              />
            </g>
            <path
              fill="#CCCCCC"
              d="M133.3,116.2c0.1,0.6-0.3,1.2-0.9,1.4l-65.8,24.1l29.9-34.4l36.7,7.4L133.3,116.2z"
            />
            <path
              fill="#B3B3B3"
              d="M129.8,74.7l-32,38L59.7,99.4l-0.1-0.8c-0.1-0.6,0.3-1.2,0.9-1.4l67.3-24.9c0.9-0.3,1.8,0.3,1.9,1.2
			L129.8,74.7z"
            />
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default Mailbox
