import styled from 'styled-components'
// import { SwiperSlide } from 'swiper/react'

const Box = styled.div`
  -js-display: flex;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  padding: 1.5rem;
  box-sizing: border-box;
  max-width: 350px;
  min-width: 270px;
  flex-direction: column;
  margin: 0 auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  transform-origin: center;
  transition: transform 0.5s ease-out;
  backface-visibility: hidden;
  &:hover {
    transform: translateZ(0) scale(1.02) perspective(1px);
  }

  /* Content */
  .icon {
    -js-display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 50%;
    background: rgb(240, 245, 252);
    color: ${props => props.theme.primary};
    &:before {
      font-size: 2.3rem;
    }
  }
  h2 {
    font-size: ${props => props.theme.scale ** 2}rem !important;
    margin-top: 1.5rem;
    margin-bottom: ${props => props.theme.scale ** -2}rem;
    font-weight: bold;
  }
  h3 {
    // font-size: ${props => props.theme.scale}rem;
    font-size: 1rem  !important;
    color: ${props => props.theme.info};
    margin: 0;
    // margin-bottom: 1rem;
    min-height: 3em;
  }
  p {
    margin: 0;
    margin-bottom: auto;
  }
  ul, ol {
    text-align: left;
  }
  .content {
    margin-bottom: 1.5rem;
  }
  .link {
    margin-top: auto;
    margin-bottom: 0;
  }
  &.swiper-slide {
    height: auto;
  }
`

export default Box
