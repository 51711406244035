import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import Mailbox from './Mailbox'
import mightyBlue from '../../utils/mightyBlue'

interface Props {
  submitting: boolean
  submitted: boolean
}

const Svg = styled.svg`
  position: absolute;
`
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  width: 100%;
  height: 260px;
  clip-path: url(#form-header-cp);
  ${mightyBlue}
  /* MS-Edge */
  @supports (-ms-ime-align:auto) {
    height: 200px;
    display: flex;
    align-items: center;
  }
  /* IE 8+ */
  @media screen\\0 {
    height: 200px;
    display: flex;
    align-items: center;
  }
`
const Title = styled.h3`
  color: white;
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 1.5rem;
  padding-top: 2.75rem;
  /* MS-Edge */
  @supports (-ms-ime-align: auto) {
    padding: 0;
    padding-left: 1.5rem;
  }
  /* IE 8+ */
  @media screen\\0 {
    padding: 0;
    padding-left: 1.5rem;
  }
`

const Success: React.FC<Props> = (props: Props) => {
  const { submitting, submitted } = props
  const intl = useIntl()
  return (
    <>
      <Svg width="0" height="0">
        <defs>
          <clipPath id="form-header-cp" clipPathUnits="objectBoundingBox">
            <path
              d={`M -.25 0 V 1 S 0 .5, .25 .5 S .5 .75, .75 .75 S 1 .5, 1.25 .5  V 0 Z`}
            />
          </clipPath>
        </defs>
      </Svg>
      <Wrapper>
        <Mailbox mail={submitted} />
        {!(submitting || submitted) && (
          <Title>{intl.formatMessage({ id: 'contact.form_header' })}</Title>
        )}
      </Wrapper>
    </>
  )
}

export default Success
