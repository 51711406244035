import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

const useSwiperA11y = (topicIntlId: string = 'slider.defaultTopic') => {
  const intl = useIntl()
  const topic = intl.formatMessage({ id: topicIntlId })
  const a11y = React.useMemo(
    () => ({
      prevSlideMessage: intl.formatMessage(
        { id: 'slider.prevSlideMessage' },
        { topic }
      ),
      nextSlideMessage: intl.formatMessage(
        { id: 'slider.nextSlideMessage' },
        { topic }
      ),
      firstSlideMessage: intl.formatMessage(
        { id: 'slider.firstSlideMessage' },
        { topic }
      ),
      lastSlideMessage: intl.formatMessage(
        { id: 'slider.lastSlideMessage' },
        { topic }
      ),
      paginationBulletMessage: intl.formatMessage(
        {
          id: 'slider.paginationBulletMessage',
        },
        { topic }
      ),
    }),
    [intl.locale, topic]
  )
  return a11y
}

export default useSwiperA11y
