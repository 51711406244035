import { Data } from './Form'

const url = 'https://www.aristech.de/contact.php'

const submit = async (data: Data) => {
  // if (Math.random() < -100) {
  const res = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(data),
  })
  const resJson = await res.json()
  console.log('Result', resJson)
}

export default submit
