import React from 'react'
import Content from '../Content'
import styled from 'styled-components'
import { IntlShape, injectIntl } from 'gatsby-plugin-intl'
import FormHead from './FormHead'
import Form from './Form'
import submit from './submit'
import BaseHeader from '../Header'

const FormWrapper = styled.div`
  width: 400px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  padding: 1.5rem;
  margin-right: 3rem;
  position: relative;

  padding-top: 215px;

  form > * {
    width: 100%;
  }
`
const Wrapper = styled.div`
  -js-display: flex;
  display: flex;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    ${FormWrapper} {
      margin-right: 0;
      width: auto;
      max-width: 400px;
    }
  }
`
const Info = styled.div`
  a {
    -js-display: flex;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    text-decoration: none;
    color: ${props => props.theme.secondary};
    i[class^='flaticon-'] {
      color: ${props => props.theme.primary};
      font-size: 1.5em;
      margin-right: 1rem;
    }
    &.address {
      align-items: flex-start;
      p {
        margin: 0;
      }
    }
    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`
const Header = styled(BaseHeader)`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
`
const SubHeader = styled.p`
  text-align: center;
  margin-top: 0;
  padding-bottom: 3rem;
  a,
  a:link {
    color: #333;
    text-decoration: none;
  }
  a:hover {
    color: ${props => props.theme.primary};
  }
`

interface Props {
  intl: IntlShape
}

const ContactForm: React.FC<Props> = (props: Props) => {
  const { intl } = props

  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const messageRef = React.useRef<HTMLTextAreaElement>(null)

  return (
    <Content id="contact">
      <Header as="h1">
        {intl.formatMessage({ id: 'contact.mainheader' })}
      </Header>
      <SubHeader
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'contact.subheader',
          }),
        }}
      />
      <Wrapper>
        <FormWrapper>
          <FormHead submitting={submitting} submitted={submitted} />
          <Form
            submitted={submitted}
            submitting={submitting}
            onSubmit={async data => {
              setSubmitting(true)
              try {
                await submit(data)
                setSubmitted(true)
              } catch (err) {
                console.error('Could not submit', err)
              }
              setSubmitting(false)
            }}
            onReset={() => {
              setSubmitted(false)
              const textArea = messageRef.current
              if (textArea) {
                textArea.focus()
              }
            }}
            messageRef={messageRef}
          />
        </FormWrapper>
        <Info>
          <h3 id="contact">
            {intl.formatMessage({ id: 'contact.office_header' })}
          </h3>
          <div>
            <a href={`mailto:${intl.formatMessage({ id: 'contact.mail_to' })}`}>
              <i className="flaticon-mail" />{' '}
              {intl.formatMessage({ id: 'contact.mail_to' })}
            </a>
            <a
              href={`tel:${intl.formatMessage({ id: 'contact.phone_number' })}`}
            >
              <i className="flaticon-phone" />{' '}
              {intl.formatMessage({ id: 'contact.phone_number_pretty' })}
            </a>
            <a
              href="https://goo.gl/maps/1BvbHsnC3byUsFJ78"
              target="_blank"
              rel="noopener noreferrer"
              className="address"
            >
              <i className="flaticon-pin" />{' '}
              <div>
                {intl
                  .formatMessage({ id: 'contact.address' })
                  .split('\n')
                  .map((text, idx) => (
                    <p key={idx}>{text}</p>
                  ))}
              </div>
            </a>
          </div>
        </Info>
      </Wrapper>
    </Content>
  )
}

export default injectIntl(ContactForm)
